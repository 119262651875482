<script lang="ts" setup>
import CommonAPI from '~/api/common';
import type { ResponseError } from '~/api/utils/types';
import background from '~/assets/images/subscribe_section_background.png';

const TEST_ID = 'section-subscribe';

const { t } = useI18n();
const modalStore = useModalStore();

const email = ref('');

const { execute, isLoading } = useRequest(CommonAPI.subscribe, () => ({
  body: { email: email.value },
}));

const onSubmit = async () => {
  execute()
    ?.then(() => {
      modalStore.showSuccess({
        title: t('message.success'),
        action: t('message.return'),
      });
      email.value = '';
    })
    .catch((e: ResponseError) => {
      modalStore.showError({
        title: getFetchErrorMessage(e, t('message.error')),
        action: t('message.return'),
      });
    });
};
</script>

<template>
  <section class="sectionSubscribe" :data-test-id="TEST_ID">
    <NuxtImg
      :sizes="IMAGE_SIZES.fullscreen"
      format="webp"
      :src="background"
      class="sectionSubscribe__image"
      :alt="t('image_alt')"
    />

    <div class="sectionSubscribe__content">
      <h3 class="sectionSubscribe__title">{{ t('title') }}</h3>

      <form @submit.prevent="onSubmit">
        <label class="sectionSubscribe__field">
          <input
            v-model="email"
            autocomplete="email"
            inputmode="email"
            name="email"
            placeholder="email@example.ru"
            class="sectionSubscribe__input"
            :data-test-id="`${TEST_ID}-email-input`"
          />
          <span class="sectionSubscribe__hint">{{ t('hint') }}</span>
        </label>

        <BaseButton
          outlined
          :loading="isLoading"
          mobile-size="xs"
          type="submit"
          class="sectionSubscribe__submit"
          :data-test-id="`${TEST_ID}-submit`"
        >
          {{ t('action') }}
        </BaseButton>
      </form>
    </div>
  </section>
</template>

<i18n>
ru:
  title: Подпишитесь на нашу рассылку и узнайте первыми о наших новостях и новинках
  hint: Нажимая кнопку “Подписаться”, вы соглашаетесь с условиями использования сайта
  image_alt: Подпишитесь на рассылку от Don't Touch My Skin
  action: Подписаться
  message:
    success: Вы успешно подписались на рассылку
    error: Произошла ошибка, повторите попытку позже
    return: Вернуться

en:
  title: Subscribe to our newsletter and be the first to know about our news and new products
  hint: By clicking the “Subscribe” button, you agree to the terms of use of the site
  image_alt: Subscribe to the Don't Touch My Skin newsletter
  action: Subscribe
  message:
    success: You have successfully subscribed to the newsletter
    error: An error occurred, please try again later
    return: Return
</i18n>

<style lang="scss">
.sectionSubscribe {
  position: relative;
  height: 536px;

  @include mq('sm') {
    height: 407px;
  }

  &__image {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    left: 0;
    padding: 33px 18px 28px;
    height: 100%;
    width: 100%;

    @include mq('sm') {
      padding: 26px 20px 25px;
    }
  }

  &__title {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.2px;
    color: #ffffff;
    margin: 0;
    width: 535px;
    max-width: 100%;

    @include mq('sm') {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__field {
    padding-left: 2px;
  }

  &__input {
    width: 583px;
    display: block;
    border: 0;
    border-bottom: 1px solid #ffffff;
    background: none;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    outline: none;
    padding: 7px 0;
    max-width: 100%;

    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }

    @include mq('sm') {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__hint {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #ffffff;
    display: block;
    margin-top: 15px;
    margin-bottom: 25px;

    @include mq('sm') {
      font-size: 12px;
      line-height: 16px;
      width: 275px;
      max-width: 100%;
      margin-top: 12px;
      margin-bottom: 29px;
    }
  }

  &__submit {
    @include mq('sm') {
      width: 100%;
    }
  }
}
</style>
